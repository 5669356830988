const envDev = 'seguros';

const env = {
    local: {
        url: 'http://127.0.0.1:8000/api/v1/',
        defaultColor: 'light.greenmoss',
    },
    seguros: {
        url: 'https://segurosoportunidades.com.co//api/v1/',
        defaultColor: 'light.greenmoss',
    }
}
export const apiUrl = env[envDev].url;
export const urlAssets = apiUrl.substring(0, apiUrl.length-8);
export const versionApp = '1.2.14';
export const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
}
//design
export let lightTheme = {
    base: '#0D3531',
    primary: '#009CDF',
    secondary: '#FFFFFF',
    accent: '#d8d9d4',
    error: '#ff0000',
    foot: '#254945',
    anchor: '#8c9eff',
    textcolor: '#000000'
};

export let darkTheme = {
    base: '#000000',
    primary: '#000000',
    secondary: '#000000',
    accent: '#BAE310',
    error: '#ff0000',
    foot: '#000000',
    textcolor: '#ffffff',
};
export let defaultDarkTheme = false;
export const elevation = 1;