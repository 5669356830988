class Storage{
    setUser(data){
        localStorage.setItem('user', JSON.stringify({
            'name': data.name,
            'lastName' : data.lastName,
            'email' : data.email,
        }));
        localStorage.setItem('menu', data.menu);
        localStorage.setItem('token', data.token);
    }
    getUser(){
        let userString = localStorage.getItem('user');
        if(userString != null){
            let user = JSON.parse(userString);
            return {
                name : user.name,
                lastName : user.lastName,
                email : user.email,
                token : localStorage.getItem('token'),
            }
        }else{
            return false;
        }
    }

    getToken(){
        let token = localStorage.getItem('token');
        if(token ==null) return false;
        else return token;
    }

    getMenu(){
        let menu = localStorage.getItem('menu');
        if(menu != null){
            return JSON.parse(menu);
        }else{
            return false;
        }
    }

    removeData(){
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('menu');

    }
}
export default new Storage()