import Vue from 'vue'
import VueRouter from 'vue-router'
import IsAuth from '@/store/modules/IsAuth';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false }

  },
  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/carousel',
    name: 'carousel',
    component: () => import('../views/Carousel.vue'),
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const authenticatedUser = IsAuth.auth();

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && ! authenticatedUser){
    next('/');
  }
  else next();
});

export default router
